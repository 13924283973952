import React, { useState } from "react";
import Iframe from "react-iframe";
import { Sidebar } from "primereact/sidebar";
import { Link } from "react-router-dom";
import SpinwheelConfiguration from "./speen-wheel-configuration";
import { Helmet } from "react-helmet";

const Game = () => {
  const gamesData = [
    {
      id: 1,
      title: "Duck Hunt",
      img: "https://miro.medium.com/v2/resize:fit:1400/0*YjLiMKsf7NlIKVaU",
      game: "https://duckhuntjs.com/",
    },
    {
      id: 2,
      title: "Triangle: Back To Home",
      img: "https://js13kgames.com/games/triangle-back-to-home/__big.jpg",
      game: "https://js13kgames.com/games/triangle-back-to-home/index.html",
    },
    {
      id: 3,
      title: "Bounce Back",
      img: "https://js13kgames.com/games/bounce-back/__big.jpg",
      game: "https://js13kgames.com/games/bounce-back/index.html",
    },
    {
      id: 4,
      title: "Wander",
      img: "https://js13kgames.com/games/wander/__big.jpg",
      game: "https://js13kgames.com/games/wander/index.html",
    },
    {
      id: 5,
      title: "Offline Paradise",
      img: "https://js13kgames.com/games/offline-paradise/__big.jpg",
      game: "https://js13kgames.com/games/offline-paradise/index.html",
    },
    {
      id: 6,
      title: "Planetfall",
      img: "https://js13kgames.com/games/planetfall/__big.jpg",
      game: "https://js13kgames.com/games/planetfall/index.html",
    },
    {
      id: 7,
      title: "Quest of Tod",
      img: "https://js13kgames.com/games/quest-of-tod/__big.jpg",
      game: "https://js13kgames.com/games/quest-of-tod/index.html",
    },
    {
      id: 8,
      title: "Car Racer",
      img: "https://js13kgames.com/games/racer/__big.jpg",
      game: "https://js13kgames.com/games/racer/index.html",
    },
    {
      id: 9,
      title: "Everyone's Sky",
      img: "https://js13kgames.com/games/everyones-sky/__big.jpg",
      game: "https://js13kgames.com/games/everyones-sky/index.html",
    },
    {
      id: 10,
      title: "Pizza Undelivery",
      img: "https://js13kgames.com/games/pizza-undelivery/__big.jpg",
      game: "https://js13kgames.com/games/pizza-undelivery/index.html",
    },
    {
      id: 11,
      title: "Jump N Jump",
      img: "https://user-images.githubusercontent.com/2490482/74604912-8d1f4f80-50ba-11ea-8f66-f52eb0776077.png",
      game: "https://grahamthecoder.github.io/jump-n-bump/",
    },
    {
      id: 12,
      title: "Play Back",
      img: "https://js13kgames.com/games/play-back/__big.jpg",
      game: "https://js13kgames.com/games/play-back/index.html",
    },
    {
      id: 13,
      title: "Back Attacker",
      img: "https://js13kgames.com/games/back-attacker/__big.jpg",
      game: "https://js13kgames.com/games/back-attacker/index.html",
    },
    {
      id: 14,
      title: "1024 Moves",
      img: "https://js13kgames.com/games/1024-moves/__big.jpg",
      game: "https://js13kgames.com/games/1024-moves/index.html",
    },
    {
      id: 15,
      title: "Re Wire",
      img: "https://js13kgames.com/games/re-wire/__big.jpg",
      game: "https://js13kgames.com/games/re-wire/index.html",
    },
    {
      id: 16,
      title: "Enmeshed",
      img: "https://js13kgames.com/games/enmeshed/__big.jpg",
      game: "https://js13kgames.com/games/enmeshed/index.html",
    },
    {
      id: 17,
      title: "Lost Treasure",
      img: "https://js13kgames.com/games/lost-treasure/__big.jpg",
      game: "https://js13kgames.com/games/lost-treasure/index.html",
    },
    {
      id: 18,
      title: "Under Rum",
      img: "https://js13kgames.com/games/underrun/__big.jpg",
      game: "https://js13kgames.com/games/underrun/index.html",
    },
    {
      id: 19,
      title: "Retro Haunt",
      img: "https://js13kgames.com/games/retrohaunt/__big.jpg",
      game: "https://js13kgames.com/games/retrohaunt/index.html",
    },
    {
      id: 20,
      title: "SPACECRAFT",
      img: "https://js13kgames.com/games/spacecraft/__big.jpg",
      game: "https://js13kgames.com/games/spacecraft/index.html",
    },
  ];

  const [game, setGame] = useState("");
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Online Games | Play Game 2 Win</title>

        <meta name="title" content="Online Games | Play Game 2 Win" />
        <meta
          name="description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          name="keywords"
          content="online games, win prizes, PlayGame2Win, game rewards, prize-winning games, gaming platform, play and win, online competitions, free games, real prizes"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Online Games | Play Game 2 Win" />
        <meta
          property="og:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta
          property="twitter:title"
          content="Online Games | Play Game 2 Win"
        />
        <meta
          property="twitter:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>
      <Sidebar
        visible={visible}
        style={{ width: "100%", backgroundColor: "#000" }}
        onHide={() => setVisible(false)}
      >
        <Iframe
          url={game}
          id="gameIframe"
          className=""
          display="block"
          position="relative"
          frameBorder={0}
          styles={{ height: "88vh", width: "100%" }}
        />
      </Sidebar>
      <SpinwheelConfiguration game_id={1} />
      <div className="page-section">
        <div className="container">
          <div className="row">
            {gamesData.map((data) => (
              <div className="col-lg-4" key={data.id}>
                <div
                  className="card-service wow fadeInUp"
                  style={{ backgroundColor: "#1E1E1E" }}
                >
                  <div className="body">
                    <img style={{ width: "100%" }} src={data.img} />
                    <h5 className="text-secondary my-3 text-white">
                      {data.title}
                    </h5>
                    <Link
                      onClick={() => {
                        setGame(data.game);
                        setVisible(true);
                      }}
                      className="btn btn-primary"
                    >
                      Play Now
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Game;
