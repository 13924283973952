import React, { useEffect, useState, useRef } from "react";
import { Config } from "./Config";
import { ProgressBar } from "primereact/progressbar";
import { Avatar } from "primereact/avatar";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: Yup.string()
    .required("Contact number is required")
    .matches(/^\d{10}$/, "Contact number must be 10 digits"),
});

const Countdown = ({ targetDate, onTimerEnd }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        if (onTimerEnd) {
          onTimerEnd();
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div
      style={{
        fontSize: 28,
        color: "#FFC300",
        textAlign: "center",
        marginBottom: 15,
      }}
    >
      {timeLeft.days}d : {timeLeft.hours}h : {timeLeft.minutes}m :{" "}
      {timeLeft.seconds}s
    </div>
  );
};

const Quiz = (props) => {
  const [data, setData] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [player, setPlayer] = useState([]);
  const [displayFromStart, setDisplayFromStart] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [targetDate, setLastDate] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [contest, setContest] = useState(props.contest);
  const [score, setScore] = useState(0);
  const [scoreBoard, setSB] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form, setForm] = useState(false);
  const [playerCount, setPlayerCount] = useState(generateRandomPlayerCount());

  const [coin, setCoin] = useState(localStorage.getItem("coin"));
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [contact, setContact] = useState(localStorage.getItem("contact") || "");
  const [finish, setFinish] = useState("");

  function generateRandomPlayerCount() {
    return Math.floor(Math.random() * (5000 - 4000 + 1)) + 4000; // Generates a random number between 100 and 1000
  }

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);

  const updateDisplayRange = () => {
    setDisplayFromStart(true);
  };

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000); // 1000 ms = 1 second
    }
  };

  const stopTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const resetTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    setTime(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    // Set up the interval to update the player count every 5 seconds
    const interval = setInterval(() => {
      setPlayerCount(generateRandomPlayerCount());
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchData();
  }, [props.category]);

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      contact: contact,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        const formData = new FormData();
        formData.append("API_KEY", Config.API_KEY);
        formData.append("op", "addUser");
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("contact", values.contact);
        formData.append("score", score);
        formData.append("timer", time);

        if (contest) {
          formData.append("contest", data.contest.id);
        }

        const response = await fetch(`${Config.API_URL}/quiz.php`, {
          method: "POST",
          body: formData,
        });

        const fdata = await response.json();

        localStorage.setItem("name", values.name);
        localStorage.setItem("email", values.email);
        localStorage.setItem("contact", values.contact);
        localStorage.setItem("user_id", fdata.user_id);
        localStorage.setItem("key", fdata.key);
        localStorage.setItem("coin", fdata.coin);
        localStorage.setItem("reff_id", fdata.reff_id);

        fetchData(); // Ensure fetchData is defined and used properly
        setStatus({ success: "Form submitted successfully!" });
      } catch (error) {
        console.error("Error fetching data:", error);
        setStatus({ error: "Failed to submit the form. Please try again." });
      } finally {
        setSubmitting(false);
      }
    },
  });

  // const sendData = () => {
  //   const formData = new FormData();
  //   formData.append("API_KEY", Config.API_KEY);
  //   formData.append("op", "addUser");
  //   formData.append("name", name);
  //   formData.append("email", email);
  //   formData.append("contact", contact);
  //   formData.append("score", score);
  //   formData.append("timer", time);

  //   if (props.contest) {
  //     formData.append("contest", data.contest.id);
  //   }

  //   fetch(`${Config.API_URL}/quiz.php`, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((fdata) => {
  //       localStorage.setItem("name", name);
  //       localStorage.setItem("email", email);
  //       localStorage.setItem("contact", contact);
  //       localStorage.setItem("user_id", fdata.user_id);
  //       localStorage.setItem("key", fdata.key);
  //       localStorage.setItem("coin", fdata.coin);
  //       localStorage.setItem("reff_id", fdata.reff_id);
  //       setForm(true);
  //       fetchData();
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  const fetchData = () => {
    setScore(0);

    setData([]);
    setQuiz([]);
    setPlayer([]);
    setCurrentQuestionIndex(0);
    setLastDate("");
    setFeedback(null);
    setScore(0);
    setTime(0);
    setSB(false);
    setProgress(0);
    setForm(false);

    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "fetchRandomQun");
    formData.append("category", props.category);
    formData.append("contest", props.contest);

    if (email !== "") {
      formData.append("email", email);
    }

    fetch(`${Config.API_URL}/quiz.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (data.user_finish == 1) {
          setSB(true);
          setForm(true);
          setScore(data.user.score);
          setTime(data.user.time);
        }
        setQuiz(data.quiz);
        setData(data);
        setPlayer(data.player);
        setLastDate(data.contest.time);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [props.category, props.contest]);

  useEffect(() => {
    let newIndex = quiz.length - 1;
    if (currentQuestionIndex === 1) {
      startTimer();
    } else if (currentQuestionIndex === newIndex) {
      stopTimer();
    }
  }, [currentQuestionIndex]);

  const handleOptionClick = (option) => {
    if (currentQuestionIndex === 8) {
      updateDisplayRange();
    }

    const correctAnswer = quiz[currentQuestionIndex].ans;
    if (option === correctAnswer) {
      setFeedback({ correct: true, selected: option });
      setScore((prevScore) => prevScore + 1); // Increment score for correct answer
      localStorage.setItem("coin", parseInt(coin) + score);
    } else {
      setFeedback({ correct: false, selected: option });
    }

    setTimeout(() => {
      setFeedback(null);
      // setSelectedOption(null);
      setProgress(progress + 10);
      if (currentQuestionIndex < quiz.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        // scoreData();
        setSB(true);
        setCurrentQuestionIndex(0); // Reset to the first question or handle end of quiz
        setProgress(0);
      }
    }, 1000);
  };

  if (quiz.length === 0) return <div>Loading...</div>;

  const currentQuestion = quiz[currentQuestionIndex];

  const cardContainerStyle = {
    display: "flex",
    border: "1px solid #000",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    backgroundColor: "#1e1e1e",
  };

  const cardImageStyle = {
    width: "100px",
    objectFit: "cover",
  };

  const cardContentStyle = {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const cardTitleStyle = {
    margin: "0 0 8px",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#FFF",
  };

  const cardDescriptionStyle = {
    margin: "0",
    color: "#CCC",
  };

  const shareOnWhatsApp = () => {
    const text = encodeURIComponent(
      "Play Quiz game and win a best price money. Check out this amazing link: "
    );
    const url = encodeURIComponent(
      `https://playgame2win.com/${localStorage.getItem("reff_id")}`
    ); // Replace with your link

    const whatsappUrl = `https://wa.me/?text=${text}${url}`;

    window.open(whatsappUrl, "_blank");
  };

  const UsedCard = (props) => {
    const cardContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#333",
      borderRadius: "8px",
    };

    return (
      <div style={cardContainerStyle} className="p-2 mx-3 mb-1">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "#FFF", marginTop: 4, marginRight: 10 }}>
            {props.rank}
          </span>
          <Avatar label="J" size="small" shape="circle" />
          <span className="py-1 px-2">
            <span style={{ fontSize: 18, color: "#ccc" }}>{props.name}</span>
            <i
              className="fa-solid fa-circle"
              style={{
                fontSize: 12,
                marginLeft: 10,
                marginRight: 5,
                color: "green",
              }}
            ></i>
            <span style={{ fontSize: 12, color: "#666" }}>
              {formatTime(props.minute)} Minute
            </span>
          </span>
        </div>
        {props.rank == 1 ? (
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/winner.png`}
            style={{
              width: "25px",
              height: "25px",
              marginTop: 4,
              marginRight: 15,
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <div
          className="page-banner home-banner"
          style={{
            backgroundColor: "#14222F",
            backgroundImage: scoreBoard
              ? `url("https://cdn.dribbble.com/users/1277402/screenshots/5986513/media/b5b1e85ebbeebaad5bdb2ebf274f0497.gif")`
              : "none",
          }}
        >
          <div className="row ">
            {scoreBoard ? (
              form ? (
                <div className="col-md-6 py-6 px-5 align-items-center">
                  <div className="flex justify-content-center flex-wrap flex-row mt-4">
                    <img
                      src="https://media3.giphy.com/media/ZMVwfXq4d1p3GOx50w/giphy.gif?cid=6c09b952ksl7dzmyabq8b9j4lesppo8zu9tacnk5tf2mjr39&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=s"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="flex justify-content-center flex-wrap flex-row mt-4 text-light">
                    <h4 style={{ fontWeight: "bold" }}>Score: {score} / 10</h4>
                  </div>
                  <div className="flex justify-content-center flex-wrap flex-row text-light">
                    <h4
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Open-24-Display, sans-serif",
                        letterSpacing: 2,
                      }}
                    >
                      Time {formatTime(time)}
                    </h4>
                  </div>
                  <div className="flex justify-content-center flex-wrap flex-row mt-2 text-light">
                    {"Rewards : 450 " + " "}
                    <i
                      className="fa-solid fa-coins mt-1"
                      style={{ color: "gold", marginLeft: 8 }}
                    ></i>
                  </div>

                  <div
                    style={cardContainerStyle}
                    className="mx-3 mt-4"
                    onClick={shareOnWhatsApp}
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png"
                      style={{ width: 50, height: 50, margin: 16 }}
                    />
                    <div style={cardContentStyle} className="text-white">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Share to clime your reward
                      </h4>
                      <p style={{ fontSize: 14 }}>
                        Share this link to 5 people on whatsapp to clime your
                        reward
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-6 py-6 px-5">
                  <h4
                    className="text-white mt-4"
                    style={{ textAlign: "center" }}
                  >
                    Save Your Score
                  </h4>
                  <p
                    style={{ ...cardDescriptionStyle, textAlign: "center" }}
                    className="mb-3"
                  >
                    Save your score and claim your reward and win a prize
                  </p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control mb-2 ${
                          formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Your Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="invalid-feedback">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        value={email}
                        className={`form-control mb-2 ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter your email.."
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="invalid-feedback">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        value={contact}
                        className={`form-control mb-2 ${
                          formik.touched.contact && formik.errors.contact
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter contact number"
                        name="contact"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.contact && formik.errors.contact ? (
                        <div className="invalid-feedback">
                          {formik.errors.contact}
                        </div>
                      ) : null}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-success btn-block mt-2"
                      disabled={formik.isSubmitting} // Disable button while submitting
                    >
                      Save
                    </button>

                    {formik.status && formik.status.success && (
                      <div className="alert alert-success mt-2">
                        {formik.status.success}
                      </div>
                    )}
                    {formik.status && formik.status.error && (
                      <div className="alert alert-danger mt-2">
                        {formik.status.error}
                      </div>
                    )}
                  </form>
                </div>
              )
            ) : (
              <div className="col-md-6 py-6 px-5">
                <h1 className="mb-4 text-light">Play Quiz and Win Coins!</h1>
                <ProgressBar value={progress} className="mb-4"></ProgressBar>
                <div key={currentQuestion.id} className="mb-3 text-light">
                  <h5 className="mb-3">
                    {currentQuestionIndex + 1 + ". " + currentQuestion.qun}
                  </h5>
                  {currentQuestion.option.map((opt, index) => {
                    const isSelected = feedback && feedback.selected === opt;
                    const isCorrect = isSelected && feedback.correct;
                    const isWrong = isSelected && !feedback.correct;
                    const correctOption =
                      !isSelected && feedback && opt === currentQuestion.ans;

                    const optionStyle = isCorrect
                      ? { backgroundColor: "green", color: "#fff" }
                      : isWrong
                      ? { backgroundColor: "red", color: "#fff" }
                      : correctOption
                      ? { backgroundColor: "green", color: "#fff" }
                      : { cursor: "pointer", backgroundColor: "#666" };

                    return (
                      <div
                        key={index}
                        onClick={() => handleOptionClick(opt)}
                        className={`shadow-3 m-2 p-2 border-round-sm flex font-semibold text-light`}
                        style={optionStyle}
                      >
                        {index + 1 + ". " + opt}
                      </div>
                    );
                  })}
                </div>

                <div
                  className="shadow-3 border-round-lg pt-2 m-2 text-light"
                  style={{ backgroundColor: "#1E1E1E" }}
                >
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-md-12">
                      <h5 className="text-align-center">
                        <i class="fa-solid fa-clock"></i>
                        <span
                          style={{
                            fontFamily: "Open-24-Display, sans-serif",
                            color: "red",
                          }}
                        >
                          {" " + formatTime(time)}
                        </span>
                      </h5>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                      <h5>Score: {score} / 10</h5>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                      <h5>
                        <i
                          className="fa-solid fa-coins"
                          style={{ color: "gold" }}
                        ></i>{" "}
                        Coins: {score}
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  className="shadow-3 border-round-lg pt-2 m-2 px-2 py-2 text-light"
                  style={{ backgroundColor: "#1E1E1E" }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/720/720284.png"
                    alt=""
                    height="25"
                    width="25"
                  />
                  <span className="px-3">
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      ACTIVE PLAYERS {playerCount} +
                    </span>
                  </span>
                </div>
              </div>
            )}

            <div className="col-md-6 py-6 wow zoomIn">
              {props.contest ? (
                <>
                  <Countdown
                    targetDate={targetDate}
                    onTimerEnd={() => {
                      console.log("Timer off");
                    }}
                  />
                  <div style={cardContainerStyle} className="mx-3 mb-3">
                    <img src={data.contest.img} alt="" style={cardImageStyle} />
                    <div style={cardContentStyle}>
                      <h2 style={cardTitleStyle}>{data.contest.name}</h2>
                      <p style={cardDescriptionStyle}>
                        Complete all question in minimum time and win price
                      </p>
                    </div>
                  </div>
                  {player.map((player, index) => (
                    <UsedCard
                      key={index}
                      name={player.name}
                      minute={player.minute}
                      rank={index + 1}
                    />
                  ))}
                </>
              ) : (
                <>
                  <center>
                    <img
                      src="https://png.pngtree.com/png-vector/20220723/ourmid/pngtree-kids-quiz-playing-intelligent-boy-and-girl-vector-png-image_6044133.png"
                      alt=""
                    />
                  </center>

                  <div style={cardContainerStyle} className="p-2 mx-3 mt-3">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/720/720284.png"
                      alt=""
                      width="60"
                    />
                    <span className="py-1 px-3">
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {playerCount} +
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "#ccc",
                        }}
                      >
                        ACTIVE PLAYERS
                      </span>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quiz;
