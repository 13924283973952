import React, { useEffect, useState } from "react";
import { Config } from "./Config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="page-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 py-3 text-light">
              <h2 className="title-section text-light">Privacy Policy</h2>
              <div className="divider"></div>

              <p>
                Your privacy is critically important to us. This Privacy Policy
                applies to our website,
                [https://playgame2win.com](https://playgame2win.com). We respect
                your privacy and are committed to protecting personally
                identifiable information you may provide us through the website.
                This Privacy Policy explains what information may be collected
                on our website, how we use this information, and under what
                circumstances we may disclose the information to third parties.
              </p>
              <p>
                This Privacy Policy, together with the Terms and Conditions
                posted on our website, set forth the general rules and policies
                governing your use of our website. Depending on your activities
                when visiting our website, you may be required to agree to
                additional terms and conditions.
              </p>
              <ul>
                <li>
                  <b>Fun Purpose Only</b>:This website is not involved in
                  promoting gambling. It is solely for fun purposes.
                </li>
                <li>
                  <b>Prizes</b>: Game winners receive prizes according to our
                  terms and conditions.
                </li>
                <li>
                  <b>Cookies</b>: We use user cookies.
                </li>
                <li>
                  <b>Third-Party Ads</b>: We may use third-party AdSense in the
                  future.
                </li>
                <li>
                  <b>Legal Compliance</b>: We adhere to all applicable laws in
                  India regarding gaming websites.
                </li>
                <li>
                  <b>Children's Information</b>: Protection of children's
                  information is paramount.
                </li>
                <li>
                  <b>CGPA and GS</b>: (Please specify these terms if they are
                  acronyms or specific points related to your policy.)
                </li>
              </ul>
              <b>Website Visitors</b>
              <p>
                Like most website operators, we collect
                non-personally-identifying information of the sort that web
                browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request. Our purpose in collecting
                non-personally identifying information is to better understand
                how our visitors use the website. From time to time, we may
                release non-personally-identifying information in the aggregate,
                e.g., by publishing a report on trends in the usage of our
                website.
              </p>
              <p>
                We also collect potentially personally-identifying information
                like Internet Protocol (IP) addresses for logged-in users and
                for users leaving comments on our site. We only disclose
                logged-in user and commenter IP addresses under the same
                circumstances that we use and disclose personally-identifying
                information as described below.
              </p>

              <b>Gathering of Personally-Identifying Information</b>
              <p>
                Certain visitors to our website choose to interact with us in
                ways that require us to gather personally-identifying
                information. The amount and type of information that we gather
                depends on the nature of the interaction. For example, we ask
                visitors who sign up to provide a username and email address.
              </p>

              <b>Security</b>
              <p>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet,
                or method of electronic storage is 100% secure. While we strive
                to use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
              </p>

              <b>Advertisements</b>
              <p>
                Ads appearing on our website may be delivered to users by
                advertising partners, who may set cookies. These cookies allow
                the ad server to recognize your computer each time they send you
                an online advertisement to compile information about you or
                others who use your computer. This information allows ad
                networks to, among other things, deliver targeted advertisements
                that they believe will be of most interest to you. This Privacy
                Policy covers the use of cookies by us and does not cover the
                use of cookies by any advertisers.
              </p>

              <b>Links to External Sites</b>
              <p>
                Our Service may contain links to external sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy and the terms and conditions of every
                site you visit. We have no control over and assume no
                responsibility for the content, privacy policies, or practices
                of any third-party sites, products, or services.
              </p>
              <b>Use of Google AdWords for Remarketing</b>
              <p>
                We may use remarketing services to advertise on third-party
                websites (including Google) to previous visitors to our site.
                This could mean that we advertise to previous visitors who
                haven’t completed a task on our site, for example using the
                contact form to make an inquiry. Third-party vendors, including
                Google, use cookies to serve ads based on someone’s past visits.
                Any data collected will be used in accordance with our own
                privacy policy and Google’s privacy policy. You can set
                preferences for how Google advertises to you using the Google Ad
                Preferences page, and if you want to, you can opt-out of
                interest-based advertising entirely by cookie settings or
                permanently using a browser plugin.
              </p>
              <b>Protection of Certain Personally-Identifying Information</b>
              <p>
                We disclose potentially personally-identifying and
                personally-identifying information only to those of our
                employees, contractors, and affiliated organizations that (i)
                need to know that information in order to process it on our
                behalf or to provide services available at our website, and (ii)
                that have agreed not to disclose it to others. Some of those
                employees, contractors, and affiliated organizations may be
                located outside of your home country; by using our website, you
                consent to the transfer of such information to them. We will not
                rent or sell potentially personally-identifying and
                personally-identifying information to anyone. Other than to our
                employees, contractors, and affiliated organizations, as
                described above, we disclose potentially personally-identifying
                and personally-identifying information only in response to a
                subpoena, court order, or other governmental requests, or when
                we believe in good faith that disclosure is reasonably necessary
                to protect the property or rights of our website, third parties,
                or the public at large.
              </p>
              <p>
                If you are a registered user of our website and have supplied
                your email address, we may occasionally send you an email to
                tell you about new features, solicit your feedback, or just keep
                you up to date with what’s going on with us and our products. We
                primarily use our blog to communicate this type of information,
                so we expect to keep this type of email to a minimum. If you
                send us a request (for example via a support email or via one of
                our feedback mechanisms), we reserve the right to publish it in
                order to help us clarify or respond to your request or to help
                us support other users. We take all measures reasonably
                necessary to protect against the unauthorized access, use,
                alteration, or destruction of potentially personally-identifying
                and personally-identifying information.
              </p>

              <b>Aggregated Statistics</b>
              <p>
                We may collect statistics about the behavior of visitors to our
                website. We may display this information publicly or provide it
                to others. However, we do not disclose your
                personally-identifying information.
              </p>

              <b>Affiliate Disclosure</b>
              <p>
                This site uses affiliate links and does earn a commission from
                certain links. This does not affect your purchases or the price
                you may pay
              </p>
              <b>Cookies</b>
              <p>
                To enrich and perfect your online experience, we use “Cookies”,
                similar technologies, and services provided by others to display
                personalized content, appropriate advertising, and store your
                preferences on your computer. A cookie is a string of
                information that a website stores on a visitor’s computer, and
                that the visitor’s browser provides to the website each time the
                visitor returns. We use cookies to help us identify and track
                visitors, their usage of our website, and their website access
                preferences. Visitors who do not wish to have cookies placed on
                their computers should set their browsers to refuse cookies
                before using our websites, with the drawback that certain
                features of our websites may not function properly without the
                aid of cookies. By continuing to navigate our website without
                changing your cookie settings, you hereby acknowledge and agree
                to our use of cookies.
              </p>
              <b>Privacy Policy Changes</b>
              <p>
                Although most changes are likely to be minor, we may change our
                Privacy Policy from time to time, and in our sole discretion. We
                encourage visitors to frequently check this page for any changes
                to our Privacy Policy. Your continued use of this site after any
                change in this Privacy Policy will constitute your acceptance of
                such change.
              </p>
              <b>Contact Us</b>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <ul>
                <li>Website : https://playgame2win.com</li>
                <li>Email-id : game.xfector@gmail.com</li>
                <li>Contact : +91 9033672710</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
