import React, { useEffect, useState } from "react";
import { Config } from "./Config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const About = () => {
  const [contestList, setConList] = useState([]);

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "contestList");

    fetch(`${Config.API_URL}/quiz.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setConList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>

        <meta name="title" content="Online Games | Play Game 2 Win" />
        <meta
          name="description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          name="keywords"
          content="online games, win prizes, PlayGame2Win, game rewards, prize-winning games, gaming platform, play and win, online competitions, free games, real prizes"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Online Games | Play Game 2 Win" />
        <meta
          property="og:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta
          property="twitter:title"
          content="Online Games | Play Game 2 Win"
        />
        <meta
          property="twitter:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>
      <div className="page-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 py-3">
              <h2 className="title-section text-white">
                Play Game 2 Win Real Prizes
              </h2>
              <div className="divider"></div>

              <p className="text-white">
                At QuizPlay, we believe that learning should be fun and
                engaging. Our platform offers a variety of quizzes across
                different categories to test your knowledge, challenge your
                friends, and learn new facts along the way. Plus, win exciting
                prizes!
              </p>
              <p className="text-white">
                <ul>
                  <li>
                    <b>Play the Quiz:</b> Answer the questions within the time
                    limit.
                  </li>
                  <li>
                    <b>Check Your Score:</b> See how well you did and compare
                    your score on the leaderboard.
                  </li>
                  <li>
                    <b>Win Prizes:</b> Score high and stand a chance to win
                    exciting prizes.
                  </li>
                  <li>
                    <b>Play the Quiz:</b> Answer the questions within the time
                    limit.
                  </li>
                  <li>
                    <b>Challenge Friends:</b> Invite your friends to play and
                    see who scores higher
                  </li>
                </ul>
              </p>
              <p className="text-white">
                Engage in fun and educational quizzes across various categories,
                compete with friends, and win exciting prizes. Join our
                community today and start testing your knowledge!
              </p>
            </div>
            <div className="col-lg-6 py-3">
              <div className="img-fluid py-3 text-center">
                <img src="../assets/img/about_frame.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i
                    className="fa-solid fa-hand fa-3x"
                    style={{ color: "gold" }}
                  ></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">
                    Participate in Contest
                  </h5>
                  <p>
                    Participate in our exciting contests to test your knowledge
                    and compete with others. Score high and stand a chance to
                    win amazing prizes!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i
                    className="fa-solid fa-check-double fa-3x"
                    style={{ color: "#FFF" }}
                  ></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">
                    Give Correct Answer
                  </h5>
                  <p>
                    Answer questions accurately to climb the leaderboard and win
                    fantastic prizes. test your knowledge and become a QuizPlay
                    champion!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i
                    className="fa-solid fa-heart fa-3x"
                    style={{ color: "red" }}
                  ></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">Win Price</h5>
                  <p>
                    Achieve top scores in our quizzes to win fantastic prizes.
                    Challenge yourself, compete with others, and claim your
                    rewards!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" style={{ backgroundColor: "#14222F" }}>
        <div className="container">
          <div className="text-center wow fadeInUp">
            <div className="subhead">Contest</div>
            <h2 className="title-section text-white">Latest Contest</h2>
            <div className="divider mx-auto"></div>
          </div>

          <div className="row">
            {contestList.map((opt, index) => {
              return (
                <div className="col-lg-4">
                  <div
                    className="card-service wow fadeInUp"
                    style={{ backgroundColor: "#1E1E1E" }}
                  >
                    <div className="header">
                      <img
                        src={opt.img}
                        style={{ borderRadius: 10, width: 100 }}
                      />
                    </div>
                    <div className="body">
                      <h5
                        className="text-secondary text-white"
                        style={{ fontWeight: "bold" }}
                      >
                        {opt.name}
                      </h5>
                      <Link
                        to={"../play/" + opt.category}
                        className="btn btn-primary mt-3"
                      >
                        Play Contest
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
