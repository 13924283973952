import React, { useState, useEffect } from "react";
import WheelComponent from "./wheel"; // Ensure the correct path
// import 'react-wheel-of-prizes/dist/index.css';
import "./spin-wheel.styles.css";
// import IMAGES from './assets';
import Confetti from "react-confetti";
import { Avatar } from "primereact/avatar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Config } from "./Config";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: Yup.string()
    .required("Contact number is required")
    .matches(/^\d{10}$/, "Contact number must be 10 digits"),
});

const SpinwheelConfiguration = (props) => {
  const [portal, setPortal] = useState(false);
  const [show, setShow] = useState(false);
  const [scoreBoard, setSB] = useState(false);
  const [shareScore, setSS] = useState(0);

  const { game_id } = props;

  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [contact, setContact] = useState(localStorage.getItem("contact") || "");
  const [user_id, setUID] = useState(localStorage.getItem("user_id") || "");
  const [key, setKey] = useState(localStorage.getItem("key") || "");
  const [form, setForm] = useState(false);

  // const [segments, setSegments] = useState([]);
  // const [color, setColor] = useState([]);

  // const fetchData = () => {
  //   const formData = new FormData();
  //   formData.append("API_KEY", Config.API_KEY);
  //   formData.append("op", "wheelData");
  //   formData.append("game_id", 1);

  //   fetch(`${Config.API_URL}/game.php`, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Fetched data:", data);
  //       const segments_array = data.option.split(",");
  //       console.log("Segments array:", segments_array);
  //       const colors = data.color.split(",");
  //       console.log("Colors array:", colors);
  //       setSegments(segments_array);
  //       setColor(colors);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const segments = [
    "I phone 13 pro max",
    "Bose surround speakers",
    "Samsung 65-Inch Crystal UHD 4K Flat Smart TV",
    "MacBook Air MGN63 14” Display, Apple M1 Chip With 8-Core",
    "KIA TELLURIDE 2022",
  ];

  const weelColors = () => {
    let arr = [];
    let colors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
    segments.forEach(() => {
      let color = colors.shift();
      arr.push(color);
      colors.push(color);
    });
    return arr;
  };

  const segColors = weelColors();

  const onFinished = (winner) => {
    setPortal(false);
    setShow(winner);
  };

  const cardContainerStyle = {
    display: "flex",
    border: "1px solid #000",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    backgroundColor: "#1e1e1e",
  };

  const cardImageStyle = {
    width: "100px",
    objectFit: "cover",
  };

  const cardContentStyle = {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const cardTitleStyle = {
    margin: "0 0 8px",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#FFF",
  };

  const cardDescriptionStyle = {
    margin: "0",
    color: "#CCC",
  };

  const shareOnWhatsApp = () => {
    setSS(shareScore + 1);
    const text = encodeURIComponent(
      "Play Quiz game and win a best price money. Check out this amazing link: "
    );
    const url = encodeURIComponent(
      `https://playgame2win.com/${localStorage.getItem("reff_id")}`
    ); // Replace with your link

    const whatsappUrl = `https://wa.me/?text=${text}${url}`;

    window.open(whatsappUrl, "_blank");
  };

  const wiinerData = [
    {
      id: 1,
      name: "Jenil Chavda",
      win: "Iphone",
    },
    {
      id: 2,
      name: "Jenil Chavda",
      win: "Iphone",
    },
    {
      id: 3,
      name: "Jenil Chavda",
      win: "Iphone",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      contact: contact,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        const formData = new FormData();
        formData.append("API_KEY", Config.API_KEY);
        formData.append("op", "register");
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("contact", values.contact);

        const response = await fetch(`${Config.API_URL}/game.php`, {
          method: "POST",
          body: formData,
        });

        const fdata = await response.json();

        localStorage.setItem("name", values.name);
        localStorage.setItem("email", values.email);
        localStorage.setItem("contact", values.contact);
        localStorage.setItem("user_id", fdata.user_id);
        localStorage.setItem("key", fdata.key);

        setUID(fdata.user_id);
        setKey(fdata.key);

        // fetchData(); // Ensure fetchData is defined and used properly
        setStatus({ success: "Form submitted successfully!" });
      } catch (error) {
        console.error("Error fetching data:", error);
        setStatus({ error: "Failed to submit the form. Please try again." });
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (shareScore > 0 && shareScore % 5 === 0) {
      addScore();
    }
  }, [shareScore]);

  const addScore = () => {
    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "addScore");
    formData.append("user_id", user_id);
    formData.append("key", key);
    formData.append("game_id", game_id);
    formData.append("score", shareScore);

    fetch(`${Config.API_URL}/game.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const UsedCard = (props) => {
    const cardContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#333",
      borderRadius: "8px",
    };

    return (
      <div style={cardContainerStyle} className="p-2 mx-3 mb-1">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "#FFF", marginTop: 4, marginRight: 10 }}>
            {props.rank}
          </span>
          <Avatar label="J" size="small" shape="circle" />
          <span className="py-1 px-2">
            <span style={{ fontSize: 18, color: "#ccc" }}>{props.name}</span>
            <i
              className="fa-solid fa-circle"
              style={{
                fontSize: 12,
                marginLeft: 10,
                marginRight: 5,
                color: "green",
              }}
            ></i>{" "}
            {props.win}
            <span style={{ fontSize: 12, color: "#666" }}></span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div
        className="page-banner home-banner"
        style={{
          backgroundColor: "#14222F",
          backgroundImage: scoreBoard
            ? `url("https://cdn.dribbble.com/users/1277402/screenshots/5986513/media/b5b1e85ebbeebaad5bdb2ebf274f0497.gif")`
            : "none",
        }}
      >
        <div className="row ">
          <div className="col-md-6 py-6 px-5 align-items-center">
            {show && <Confetti width={500} height={500} />}
            <WheelComponent
              segments={segments}
              segColors={segColors}
              winningSegment={"8"}
              onFinished={onFinished}
              primaryColor="#40E0D0"
              contrastColor="black"
              buttonText="Spin"
              isOnlyOnce={true}
            />
          </div>

          <div className="col-md-6 py-6 px-5 align-items-center">
            {show ? (
              <>
                <p
                  style={{
                    marginTop: 25,
                    color: "gold",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                  className="text-center"
                >
                  CONGRATULATIONS
                  <br />
                  YOU WON
                </p>
                <p
                  className="text-center text-light"
                  style={{ fontSize: 25, fontWeight: "bold" }}
                >
                  {show}
                </p>

                {user_id == "" ? (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control mb-2 ${
                          formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Your Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="invalid-feedback">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        value={email}
                        className={`form-control mb-2 ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter your email.."
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="invalid-feedback">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        value={contact}
                        className={`form-control mb-2 ${
                          formik.touched.contact && formik.errors.contact
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter contact number"
                        name="contact"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.contact && formik.errors.contact ? (
                        <div className="invalid-feedback">
                          {formik.errors.contact}
                        </div>
                      ) : null}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-success btn-block mt-2"
                      disabled={formik.isSubmitting} // Disable button while submitting
                    >
                      Save
                    </button>

                    {formik.status && formik.status.success && (
                      <div className="alert alert-success mt-2">
                        {formik.status.success}
                      </div>
                    )}
                    {formik.status && formik.status.error && (
                      <div className="alert alert-danger mt-2">
                        {formik.status.error}
                      </div>
                    )}
                  </form>
                ) : (
                  <>
                    <center>
                      <img
                        src="https://cdn.pixabay.com/animation/2023/11/29/03/39/03-39-03-19_512.gif"
                        style={{ width: 100, height: 100 }}
                      />
                    </center>
                    <div
                      style={cardContainerStyle}
                      className="mx-3 mt-4"
                      onClick={shareOnWhatsApp}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png"
                        style={{ width: 50, height: 50, margin: 16 }}
                      />
                      <div style={cardContentStyle} className="text-white">
                        <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                          Share to clime your reward
                        </h4>
                        <p style={{ fontSize: 14 }}>
                          Share this link to 5 people on whatsapp to clime your
                          reward
                        </p>
                      </div>
                    </div>
                    <p
                      className="text-center text-light mt-3"
                      style={{ fontSize: 18, fontWeight: "bold" }}
                    >
                      {"Share Score : " + shareScore}
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                <h2
                  style={{ textAlign: "center", color: "#FFF" }}
                  className="text-light"
                >
                  Spin Wheel
                  <br /> To Win Amazing real prize
                </h2>
                <p className="text-center text-light my-3">-: Our Winners :-</p>
                {wiinerData.map((player, index) => (
                  <UsedCard key={index} name={player.name} win={player.win} />
                ))}

                <div style={cardContainerStyle} className="mx-3 mt-4">
                  <img
                    src="https://cdn.pixabay.com/animation/2023/11/29/03/39/03-39-03-19_512.gif"
                    style={{ width: 100, height: 100, margin: 16 }}
                  />
                  <div style={cardContentStyle} className="text-white">
                    <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                      Share link to up your rank{" "}
                      <i className="fas fa-star" style={{ color: "gold" }}></i>
                    </h4>
                    <p style={{ fontSize: 14 }}>
                      More you share, more your rank up across all players
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpinwheelConfiguration;
