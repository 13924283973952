import React, { useEffect, useState } from "react";
import { Config } from "./Config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [articleList, setArticle] = useState([]);

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "blogList");

    fetch(`${Config.API_URL}/blog.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setArticle(data.blog);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>

        <meta name="title" content="Online Games | Play Game 2 Win" />
        <meta
          name="description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          name="keywords"
          content="online games, win prizes, PlayGame2Win, game rewards, prize-winning games, gaming platform, play and win, online competitions, free games, real prizes"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Online Games | Play Game 2 Win" />
        <meta
          property="og:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta
          property="twitter:title"
          content="Online Games | Play Game 2 Win"
        />
        <meta
          property="twitter:description"
          content="Welcome to PlayGame2Win.com, your ultimate destination for online gaming and winning real prizes! Explore a wide range of exciting games, compete with players worldwide, and stand a chance to win amazing rewards. Join now and start your winning journey!"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>

      <div className="page-section">
        <div className="container">
          <div className="row">
            {articleList.map((opt, index) => {
              return (
                <Link to={`/blog/${opt.link_tag}`} className="col-lg-4">
                  <div
                    className="card-service wow fadeInUp"
                    style={{ backgroundColor: "#1E1E1E" }}
                  >
                    <div className="header">
                      <img src={opt.img} style={{ width: "100%" }} />
                    </div>
                    <div className="body">
                      <h5 className="text-secondary text-white">{opt.title}</h5>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
