import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  useParams,
  Link,
} from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import Quiz from "./Quiz";
import About from "./About";
import Contact from "./Contact";
import Privacy from "./Privacy";
import Home from "./Home";
import Game from "./Game";
import { Config } from "./Config";
import GameArea from "./GameArea";
import BlogPage from "./BlogPage";
import Blog from "./Blog";

function App() {
  const [coin, setCoin] = useState(0);
  const menuLeft = useRef(null);
  const toast = useRef(null);

  const items = [
    {
      label: "Options",
      items: [
        { label: "Home", icon: "pi pi-home", command: () => {} },
        { label: "Game", icon: "pi pi-discord", command: () => {} },
        { label: "About Us", icon: "pi pi-users", command: () => {} },
        { label: "Contact", icon: "pi pi-phone", command: () => {} },
        { label: "Privacy Policy", icon: "pi pi-bookmark", command: () => {} },
      ],
    },
  ];

  useEffect(() => {
    const storedCoin = localStorage.getItem("coin");
    if (storedCoin !== null) {
      setCoin(parseInt(storedCoin, 10));
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <header>
          <nav
            className="navbar navbar-expand-lg navbar-light bg-dark sticky"
            data-offset="500"
          >
            <div className="container">
              <a href="#" className="navbar-brand text-white">
                PlayGame <span className="text-primary">2</span> Win
              </a>

              <button
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={(event) => menuLeft.current.toggle(event)}
                aria-controls="popup_menu_left"
                aria-haspopup
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="navbar-collapse collapse" id="navbarContent">
                <ul className="navbar-nav ml-auto text-light">
                  <li className="nav-item">
                    <NavLink
                      to="/home"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      to="/game"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      Game
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/blog"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      Blog
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/privacy"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      Privacy
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/contact"
                      className={({ isActive }) =>
                        isActive ? "nav-link text-light" : "nav-link"
                      }
                    >
                      Contact
                    </NavLink>
                  </li>
                  {coin && (
                    <li className="nav-item">
                      <a className="btn btn-primary ml-lg-2" href="#">
                        <i
                          className="fa-solid fa-coins mt-1"
                          style={{ color: "gold", marginLeft: 8 }}
                        ></i>
                        {" " + coin}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
          <Toast ref={toast}></Toast>
          <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:reff_id" element={<HomeWithParams />} />
          <Route path="home" element={<Home />} />
          <Route path="play/:category" element={<Home />} />
          <Route path="play/:category/:contest_id" element={<Home />} />
          <Route path="blog/" element={<BlogPage />} />
          <Route path="blog/:tag_name" element={<Blog />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="game" element={<Game />} />
        </Routes>

        <footer className="page-footer bg-image">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-3 py-3">
                <a
                  href="#"
                  className="navbar-brand text-white"
                  style={{ fontSize: 28 }}
                >
                  PlayGame <span className="text-primary">2</span> Win
                </a>
                <p>
                  Engage in fun and educational quizzes across various
                  categories, compete with friends, and win exciting prizes.
                  Join our community today and start testing your knowledge!
                </p>

                <div className="social-media-button">
                  <a href="#">
                    <span className="mai-logo-facebook-f"></span>
                  </a>
                  <a href="#">
                    <span className="mai-logo-twitter"></span>
                  </a>
                  <a href="#">
                    <span className="mai-logo-google-plus-g"></span>
                  </a>
                  <a href="#">
                    <span className="mai-logo-instagram"></span>
                  </a>
                  <a href="#">
                    <span className="mai-logo-youtube"></span>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 py-3">
                <h5>Company</h5>
                <ul className="footer-menu">
                  <li>
                    <Link to="../home">Home</Link>
                  </li>
                  <li>
                    <Link to="../about">About Us</Link>
                  </li>
                  <li>
                    <Link to="../contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="../privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 py-3">
                <h5>Contact Us</h5>
                <p>
                  203 Fake St. Mountain View, San Francisco, California, USA
                </p>
                <a href="#" className="footer-link">
                  +00 1122 3344 5566
                </a>
                <a href="#" className="footer-link">
                  seogram@temporary.com
                </a>
              </div>
              <div className="col-lg-3 py-3">
                <h5>Newsletter</h5>
                <p>Get updates, news or events on your mail.</p>
                <form action="#">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email.."
                  />
                  <button
                    type="submit"
                    className="btn btn-success btn-block mt-2"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>

            <p className="text-center" id="copyright">
              Copyright &copy; 2024. All rights reserved{" "}
            </p>
          </div>
        </footer>
      </BrowserRouter>
    </>
  );
}

function HomeWithParams() {
  const { reff_id } = useParams();
  const [reffReward, setRR] = useState(true);

  useEffect(() => {
    if (reffReward && reff_id) {
      const formData = new FormData();
      formData.append("API_KEY", Config.API_KEY);
      formData.append("op", "reffUser");
      formData.append("reff_id", reff_id);

      fetch(`${Config.API_URL}/quiz.php`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setRR(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [reff_id, reffReward]);

  return <Home />;
}

export default App;
