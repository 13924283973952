import React, { useEffect, useState } from "react";
import { Config } from "./Config";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submit = () => {
    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "contact");
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);

    fetch(`${Config.API_URL}/quiz.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="page-section">
        <div className="container-fluid mt-4">
          <div className="row justify-content-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <form action="#" className="contact-form py-5 px-lg-5">
                <h2 className="mb-4 font-weight-medium text-secondary text-white">
                  Get in touch
                </h2>
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" for="fname">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="fname"
                      className="form-control"
                      onChange={(e) => setFName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="text-black" for="lname">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lname"
                      className="form-control"
                      onChange={(e) => setLName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" for="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" for="subject">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="form-control"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" for="message">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="5"
                      className="form-control"
                      placeholder="Write your notes or questions here..."
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="row form-group mt-4">
                  <div className="col-md-12 text-center">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary"
                      onClick={submit}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
