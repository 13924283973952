import React, { useEffect, useState } from "react";
import { Config } from "./Config";
import Quiz from "./Quiz";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";

const Home = () => {
  const [cat, setCat] = useState("GK");
  const [contest, setContest] = useState(true);
  const { category } = useParams();
  const { contest_id } = useParams();

  useEffect(() => {
    setCat(category);
    window.scrollTo(0, 0);
  }, [category]);

  const [contestList, setConList] = useState([]);

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", Config.API_KEY);
    formData.append("op", "contestList");

    fetch(`${Config.API_URL}/quiz.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setConList(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quiz Games | Test Your Knowledge & Win Prizes</title>
        <meta
          name="title"
          content="Quiz Games |Test Your Knowledge & Win Prizes"
        />
        <meta
          name="description"
          content="Challenge your intellect with fun and engaging quiz games on PlayGame2Win.com! Answer trivia questions, compete against other players, and win exciting prizes. Whether you're a trivia buff or just looking for a challenge, our quiz games offer something for everyone. Start playing now and win big!"
        />
        <meta
          name="keywords"
          content="quiz games, trivia questions, online quiz, win prizes, PlayGame2Win, knowledge test, trivia games, play and win, quiz competitions, prize-winning games"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta
          property="og:title"
          content="Quiz Games | Test Your Knowledge & Win Prizes"
        />
        <meta
          property="og:description"
          content="Challenge your intellect with fun and engaging quiz games on PlayGame2Win.com! Answer trivia questions, compete against other players, and win exciting prizes. Whether you're a trivia buff or just looking for a challenge, our quiz games offer something for everyone. Start playing now and win big!"
        />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta
          property="twitter:title"
          content="Quiz Games | Test Your Knowledge & Win Prizes"
        />
        <meta
          property="twitter:description"
          content="Challenge your intellect with fun and engaging quiz games on PlayGame2Win.com! Answer trivia questions, compete against other players, and win exciting prizes. Whether you're a trivia buff or just looking for a challenge, our quiz games offer something for everyone. Start playing now and win big!"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </Helmet>
      <Quiz category={cat} contest_id={contest_id} contest={contest} />

      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i
                    className="fa-solid fa-trophy fa-3x"
                    style={{ color: "gold" }}
                  ></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">
                    Cricket Craze Quiz
                  </h5>
                  <p>
                    Boost your intellect with our cricket knowledge quiz and win
                    exciting prizes!
                  </p>
                  <Link to={"../play/cricket"} className="btn btn-primary">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i class="fa-solid fa-hourglass-half fa-3x"></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">
                    Indian General Knowledge
                  </h5>
                  <p>
                    Test your knowledge with engaging Indian general knowledge
                    Quiz and win amazing prizes!
                  </p>
                  <Link to={"../play/IndianGK"} className="btn btn-primary">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <i
                    class="fa-solid fa-earth-americas fa-3x"
                    style={{ color: "#007FFF" }}
                  ></i>
                </div>
                <div className="body">
                  <h5 className="text-secondary text-white">
                    International History Quiz
                  </h5>
                  <p>
                    Challenge yourself with our captivating History Quiz and win
                    exciting prizes!
                  </p>
                  <Link to={"../play/GK"} className="btn btn-primary">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" id="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 py-3 wow fadeInUp">
              <span className="subhead">About us</span>
              <h2 className="title-section text-white">
                Play contest and win price
              </h2>
              <div className="divider"></div>

              <p>
                Are you ready for an exciting opportunity to showcase your
                skills and win fantastic prizes? Join our thrilling contest,
                designed to challenge and engage participants with a series of
                fun and competitive tasks. By participating, you will not only
                have a chance to prove your abilities but also to earn points
                and climb the leaderboard. The top scorers will be awarded
                amazing rewards, ranging from [list the top prize] to [list the
                second prize] and more. This contest is a perfect blend of
                excitement, community, and recognition. You will get to meet
                like-minded individuals, make new friends, and be part of a
                vibrant community. Plus, your efforts and achievements won't go
                unnoticed as we have special prizes for participation and
                outstanding performance. Don't miss out on this golden
                opportunity to win big. Sign up now, play the contest, and take
                your shot at becoming a winner. Let's get started and see what
                you can achieve!
              </p>
              <a href="about.html" className="btn btn-primary mt-3">
                Play Now
              </a>
            </div>
            <div className="col-lg-6 py-3 wow fadeInRight">
              <div className="img-fluid py-3 text-center">
                <img
                  src="https://png.pngtree.com/png-vector/20220723/ourmid/pngtree-kids-quiz-playing-intelligent-boy-and-girl-vector-png-image_6044133.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" style={{ backgroundColor: "#14222F" }}>
        <div className="container">
          <div className="text-center wow fadeInUp">
            <div className="subhead">Contest</div>
            <h2 className="title-section text-white">Latest Contest</h2>
            <div className="divider mx-auto"></div>
          </div>

          <div className="row">
            {contestList.map((opt, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div
                    className="card-service wow fadeInUp"
                    style={{ backgroundColor: "#1E1E1E" }}
                  >
                    <div className="header">
                      <img
                        src={opt.img}
                        style={{ borderRadius: 10, width: 100 }}
                      />
                    </div>
                    <div className="body">
                      <h5
                        className="text-secondary text-white"
                        style={{ fontWeight: "bold" }}
                      >
                        {opt.name}
                      </h5>
                      <Link
                        to={"../play/" + opt.category + "/" + opt.id}
                        className="btn btn-primary mt-3"
                      >
                        Play Contest
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="page-section banner-seo-check">
        <div className="wrap bg-image">
          <div className="container text-center">
            <div className="row justify-content-center wow fadeInUp">
              <div className="col-lg-8">
                <h2 className="mb-4">Get Update For Latest Contest</h2>
                <form action="#">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email-id"
                  />
                  <button type="submit" className="btn btn-success">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" style={{ backgroundColor: "#14222F" }}>
        <div className="container">
          <div className="text-center wow fadeInUp">
            <div className="subhead">Our services</div>
            <h2 className="title-section text-white">Our Contest Winners</h2>
            <div className="divider mx-auto"></div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <img
                    src="https://iplanet.one/cdn/shop/files/iPhone_14_Plus_Blue_PDP_Image_Position-1A__WWEN.jpg?v=1691140349&width=1445"
                    style={{ borderRadius: 10, width: 100 }}
                  />
                </div>
                <div className="body">
                  <h5
                    className="text-secondary text-white"
                    style={{ fontWeight: "bold" }}
                  >
                    iPhone 14 Pro
                  </h5>
                  <p style={{ color: "#6C55F9" }}>Our Winner</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "#CCC",
                    }}
                  >
                    Jenil Chavda
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <img
                    src="https://silvera.co.in/app/uploaded/product/silvera-5028911682490575140.png"
                    style={{ borderRadius: 10, width: 100 }}
                  />
                </div>
                <div className="body">
                  <h5
                    className="text-secondary text-white"
                    style={{ fontWeight: "bold" }}
                  >
                    2 Tola Silver Coin
                  </h5>
                  <p style={{ color: "#6C55F9" }}>Our Winner</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "#CCC",
                    }}
                  >
                    Jenil Chavda
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="card-service wow fadeInUp"
                style={{ backgroundColor: "#1E1E1E" }}
              >
                <div className="header">
                  <img
                    src="https://bubhandari.net/wp-content/uploads/2020/06/Honda-Activa6G-Red.jpg"
                    style={{ borderRadius: 10, width: 100 }}
                  />
                </div>
                <div className="body">
                  <h5
                    className="text-secondary text-white"
                    style={{ fontWeight: "bold" }}
                  >
                    Activa 6G
                  </h5>
                  <p style={{ color: "#6C55F9" }}>Our Winner</p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "#CCC",
                    }}
                  >
                    Jenil Chavda
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
